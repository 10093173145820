import React from 'react';
import * as styles from './Footer.module.scss';

const Footer = () => {
  return (
    <footer className={styles.main}>
      <div>
        <p>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.dnb.no/om-oss/personvern.html"
          >
            Personvern og informasjonskapsler
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
