import React from 'react';
import * as styles from './VideoPage.module.scss';
import { StaticQuery, graphql } from 'gatsby';
import { Heading } from '@dnb/eufemia';

const VideoPage = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          sanityVideopage {
            title
            subtitle
            videos {
              _key
              title
              code
            }
          }
        }
      `}
      render={(data) => {
        const title = data.sanityVideopage.title;
        const subtitle = data.sanityVideopage.subtitle;
        const videos = data.sanityVideopage.videos;
        const videoList = videos.map((video) => {
          return (
            <div className={styles.item} key={video._key}>
              <div className={styles.container}>
                <iframe
                  className={styles.video}
                  src={`https://www.youtube.com/embed/${video.code}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allowFullScreen
                />
              </div>
              <Heading className={styles.name}>{video.title}</Heading>
            </div>
          );
        });
        return (
          <section className={styles.wrapper}>
            <Heading className={styles.title}>{title}</Heading>
            {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
            <div className={styles.list}>{videoList}</div>
          </section>
        );
      }}
    />
  );
};

export default VideoPage;
