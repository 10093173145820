import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Logo, Button } from '@dnb/eufemia';
import '@dnb/eufemia/style';
import { StatusContext } from '../../contexts/StatusProvider';
import classNames from 'classnames';
import * as styles from './Layout.module.scss';
import VideoPage from '../videopage/VideoPage';
import Overlay from '../overlay/Overlay';
import { Link } from 'gatsby';
import Tracker from '../tracker/Tracker';
import backgroundImage from '../../images/house-tiny.png';
import Footer from '../footer/Footer';

const Layout = ({ children }) => {
  const [status] = useContext(StatusContext);
  const [videoOpen, setVideoOpen] = useState(false);

  return (
    <>
      <Tracker />
      <main
        className={classNames(styles.main, {
          [styles.locked]: status?.overlay === true,
        })}
      >
        <header className={styles.header}>
          <div>
            <p>Bilforsikring</p>
          </div>
          <div>
            <Link to="/">
              <Logo color="#A5E1D2" width={70} />
            </Link>
          </div>
          <div>
            <Button
              onClick={() => {
                setVideoOpen(!videoOpen);
              }}
            >
              {videoOpen ? 'Tilbake' : 'Se kampanjefilmer'}
            </Button>
          </div>
        </header>
        <Overlay open={videoOpen === true}>
          <VideoPage />
        </Overlay>

        {children}
        <div className={styles.backgroundImage}>
          <img draggable={false} alt="" src={backgroundImage} />
        </div>
      </main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  children: null,
};

export default Layout;
