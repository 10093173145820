import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as styles from './Overlay.module.scss';
import { StatusContext } from '../../contexts/StatusProvider';

const Overlay = ({ open, children }) => {
  const [status, dispatch] = useContext(StatusContext);

  useEffect(() => {
    if (open === true) {
      dispatch({ type: 'open_overlay' });
    } else {
      dispatch({ type: 'close_overlay' });
    }
  }, [open]);

  return (
    <div
      className={classNames(styles.overlay, {
        [styles.active]: open === true,
      })}
    >
      {children}
    </div>
  );
};

Overlay.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Overlay.defaultProps = {
  open: false,
  children: null,
};

export default Overlay;
