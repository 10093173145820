import React from 'react';
import { PropTypes } from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

const SEO = ({ type, slug, title, desc, image }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          sanityGlobalSeo {
            seoData {
              ...SeoContent
            }
          }
        }
      `}
      render={(data) => {
        const defaultSeoData = data.sanityGlobalSeo.seoData;
        const seoData = {
          title: title ? title : defaultSeoData.seoTitle,
          description: desc ? desc : defaultSeoData.seoDescription,
          image: image ? image : defaultSeoData.seoImage.asset.url,
          type: type ? type : 'website',
          slug: slug ? slug : '/',
        };
        const pageUrl =
          process.env.GATSBY_CI_ENV !== 'production'
            ? 'https://dnb-eiendom-a-aa-2021-staging.herokuapp.com'
            : 'https://goderad.dnbeiendom.no';
        const debug = process.env.GATSBY_CI_ENV !== 'production';
        return (
          <Helmet
            htmlAttributes={{
              lang: 'no-nb',
            }}
            title={seoData.title}
            description={seoData.description}
            titleTemplate={seoData.title ? `%s | DNB` : null}
            meta={[
              {
                property: `og:title`,
                content: seoData.title,
              },
              {
                property: `og:description`,
                content: seoData.description,
              },
              {
                property: `og:type`,
                content: seoData.type,
              },
              {
                property: `og:image`,
                content: seoData.image,
              },
              {
                property: `og:url`,
                content: `${pageUrl}${seoData.slug}`,
              },
            ]}
          >
            {debug && <meta name="robots" content="noindex" />}
            <meta name="og:title" content={seoData.title} />
            <meta name="og:description" content={seoData.description} />
            <meta name="description" content={seoData.description} />
            <meta name="og:type" content={seoData.type} />
            <meta name="og:image" content={seoData.image} />
            <meta name="og:url" content={`${pageUrl}${seoData.slug}`} />
          </Helmet>
        );
      }}
    />
  );
};

SEO.propTypes = {
  type: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
};

SEO.defaultProps = {
  type: null,
  slug: null,
  title: null,
  description: null,
  image: null,
};

export default SEO;
